import Slider from 'react-slick';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Link } from 'gatsby';
import { bpWidth, bpWidthMobileFirst, colors } from '../../styles/Web3.0/variables.js';
import {
  headerMStyles,
  headerSStyles,
  headerXsStyles,
  bodyXlRegularUtopiaStyles,
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodyXxxlItalicUtopiaStyles,
  bodyXxlItalicUtopiaStyles,
  bodyXlItalicUtopiaStyles,
  bodyLItalicUtopiaStyles,
  eyebrowSStyles,
  eyebrowMStyles,
  bodyXxxlBoldBrownStyles,
} from '../../styles/Web3.0/typography.js';
import {
  btnLgTertiaryBlueStyles,
  btnSmTertiaryBlueStyles,
  btnLgSecondaryMediumBlueStyles,
  btnSmSecondaryMediumBlueStyles,
} from '../../styles/Web3.0/buttons.js';

export const SlickSlider = styled(Slider)`
  width: 100%;
  height: 100%;

  &.carousel-full-page .slick-list {
    height: 100%;
  }

  &.carousel-full-page .slick-track,
  &.carousel-full-page .slick-slide > div {
    height: 100%;
  }

  &.carousel-full-page .slick-slide {
    position: relative;
  }
`;

export const SlickSlide = styled.div`
  width: 100%;
  height: 100%;
`;

export const CarouselFPTextRow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(#00121900, #001219);
  padding-top: 70px;

  @media (${bpWidth.desktopSm}) {
    padding-top: 60px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 70px;
  }
`;

export const CarouselFPTextTopRow = styled(Row)`
  padding-bottom: 20px;

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 15px;
  }

  @media (${bpWidth.mobile}) {
    padding-bottom: 5px;
  }
`;

export const CarouselFPTextBottomRow = styled(Row)`
  padding-bottom: 50px;

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 40px;
  }
  @media (${bpWidth.tablet}) {
    padding-bottom: 67px;
  }
  @media (${bpWidth.mobile}) {
    padding-bottom: 80px;
    row-gap: 15px;
  }
`;

export const CarouselFPCol = styled(Col)``;

export const CarouselFPHeaderText = styled.h1`
  ${headerMStyles}
  color: ${colors.beige100};

  @media (${bpWidth.desktopSm}) {
    ${headerSStyles}
    color: ${colors.beige100};
  }

  @media (${bpWidth.mobile}) {
    ${headerXsStyles}
    color: ${colors.beige100};
  }

  svg:nth-of-type(1) {
    margin-bottom: -9px;
    height: auto;
    width: 110px;

    @media (${bpWidth.desktopSm}) {
      width: 98px;
      margin-bottom: -8px;
    }

    @media (${bpWidth.mobile}) {
      width: 72px;
      margin-bottom: -5px;
    }
  }

  svg:nth-of-type(2) {
    fill: #ffffff;
    margin-bottom: -8px;
    height: auto;
    width: 110px;

    * {
      fill: #ffffff;
    }

    @media (${bpWidth.desktopSm}) {
      width: 98px;
      margin-bottom: -8px;
    }

    @media (${bpWidth.mobile}) {
      width: 72px;
      margin-bottom: -5px;
    }
  }
`;

export const CarouselFPSubheaderText = styled.div`
  ${bodyXlRegularUtopiaStyles}
  color: ${colors.beige100};
  padding-right: 20px;
  b {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
  @media (${bpWidth.desktopSm}) {
    ${bodyLRegularUtopiaStyles}
    color: ${colors.beige100};
  }

  p {
    display: inline;
  }
  /* styling for colored Father's Day code */
  h6 {
    display: inline;
    color: ${colors.cerulean100};
    ::before {
      content: ' ';
    }
  }
`;

export const TabbedSlickSlider = styled(Slider)`
  width: 100%;
  height: 100%;
  padding-top: 30px;

  @media (${bpWidth.desktopSm}) {
    padding-top: 20px;
  }

  @media (${bpWidth.tablet}) {
    padding-top: 30px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 20px;

    .gatsby-image-wrapper.gatsby-image-wrapper-constrained img {
      height: 280px;
      object-position: bottom;
    }
  }

  &.healthier-you .slick-dots {
    text-align: left;
    bottom: -55px;
    box-sizing: border-box;
    pointer-events: none;
  }

  &.healthier-you .slick-dots li {
    width: 7.2vw;
    height: 10px;
    margin: 0 1.25vw 0 0;
    pointer-events: auto;
  }

  &.how-it-works .slick-dots li button::before {
    color: #00b4dc;
    margin: 0 1.25vw 0 0;
    pointer-events: auto;
    opacity: 0.1;
    font-size: 12px;
  }

  &.how-it-works .slick-dots li button {
    width: 100%;
    height: 100%;
  }

  &.healthier-you .slick-dots li button {
    width: 100%;
    height: 100%;
    padding: 4px 0;
  }

  &.healthier-you .slick-dots li button::before {
    content: '';
    font-family: none;
    font-size: 0;
    width: 100%;
    height: 2px;
    top: 4px;
    opacity: 1;
    background: #cccccc;

    background: linear-gradient(to right, #868689 49.5%, #cccccc 50%);
    background-size: 200% 100%;
    background-position: bottom right;
    transition: none;
  }

  &.healthier-you .slick-dots li.slick-active button::before {
    background-position: bottom left;
    transition: all 8s linear;
  }

  &.how-it-works .slick-dots li.slick-active button::before {
    opacity: 1;
    transition: all 0.3s linear;
  }

  &.how-it-works .slick-dots li.slick-active button::before {
    opacity: 1;
    transition: all 0.3s linear;
  }

  @media (max-width: 1280px) {
    &.healthier-you .slick-dots {
      text-align: center;
      bottom: -38px;
    }

    &.healthier-you .slick-dots li {
      width: 8.6vw;
      margin: 0 1.6vw 0 0;
    }
    &.healthier-you .slick-dots li:first-child {
      margin-left: 0.8vw;
    }
    &.healthier-you .slick-dots li:last-child {
      margin-right: 0.8vw;
    }
  }

  @media (max-width: 768px) {
    &.healthier-you .slick-dots {
      text-align: left;
      padding-left: calc(100vw * (1 / 8));
    }

    &.healthier-you .slick-dots li:first-child {
      margin-left: 0;
    }

    &.healthier-you .slick-dots li:last-child {
      margin-right: 0;
    }

    &.healthier-you .slick-dots li {
      width: 16.1vw;
      margin: 0 2.1vw 0 0;
    }
  }

  @media (max-width: 414px) {
    &.healthier-you .slick-dots li {
      width: 15.6vw;
      margin: 0 2.4vw 0 0;
    }
  }
`;

export const CarouselTabbedCTACol = styled(Col)`
  text-align: right;
  padding-top: 35px;

  @media (${bpWidth.desktopSm}) {
    text-align: center;
    padding-top: 60px;
  }

  @media (${bpWidth.tablet}) {
    text-align: left;
  }
`;

export const CarouselTabbedCTA = styled.a`
  ${btnLgTertiaryBlueStyles};
  text-decoration: none;
  cursor: pointer;

  @media (${bpWidth.desktopSm}) {
    ${btnSmTertiaryBlueStyles}
  }

  svg {
    width: 4px;
    margin-bottom: 2px;
    margin-left: 8px;

    @media (${bpWidth.desktopSm}) {
      margin-left: 6px;
      margin-bottom: 1px;
    }
  }

  svg,
  svg * {
    color: ${colors.cerulean400};
    fill: ${colors.cerulean400};
  }

  :hover svg,
  :hover svg * {
    color: ${colors.cerulean700};
    fill: ${colors.cerulean700};
  }
`;

export const CarouselTabbedTopRow = styled.div`
  width: 100%;
  padding-top: 120px;

  @media (${bpWidth.desktopSm}) {
    padding-top: 100px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 80px;
  }
`;

export const CarouselTabbedTopRowHowItWorks = styled.div`
  width: 100%;
  padding-top: 65px;
  padding-bottom: 50px;
  margin: 10px 0 0 10px;

  @media (${bpWidthMobileFirst.desktopSm}) {
    padding-top: 70px;
    padding-bottom: 80px;
  }
  @media (${bpWidthMobileFirst.tablet}) {
    padding-top: 70px;
    padding-bottom: 80px;
  }
`;

export const CarouselTabbedHeaderText = styled.h2`
  ${bodyXxxlItalicUtopiaStyles};
  padding-right: 20px;
  margin-top: -6px;

  @media (${bpWidth.desktopSm}) {
    ${bodyXxlItalicUtopiaStyles};
  }

  @media (${bpWidth.tablet}) {
    ${bodyXlItalicUtopiaStyles};
  }

  @media (${bpWidth.mobile}) {
    ${bodyLItalicUtopiaStyles};
    margin-top: 0;
  }
`;

export const CarouselTabbedHeaderTextHowItWorks = styled.h2`
  padding-right: 20px;
  margin-top: -6px;
  ${bodyXxxlBoldBrownStyles};
  p {
    font-size: 15px !important;
    letter-spacing: -0.16px !important;
    text-transform: none !important;
  }

  @media (${bpWidthMobileFirst.desktopSm}) {
    ${bodyXxxlBoldBrownStyles};
    p {
      font-size: 28pt;
    }
  }

  @media (${bpWidthMobileFirst.tablet}) {
    ${bodyXxxlBoldBrownStyles};
    p {
      font-size: 28px !important;
    }
  }

  @media (${bpWidthMobileFirst.mobile}) {
    ${bodyXxxlBoldBrownStyles};
    margin-top: 0;
    p {
      font-size: 16px;
    }
  }
`;

export const CarouselTabbedSubheaderText = styled.div`
  ${bodyMRegularUtopiaStyles};

  i {
    font-style: italic;
  }

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularUtopiaStyles};
  }
`;

export const CarouselHSSectionRow = styled(Row)`
  margin-top: 150px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 120px;
  }
  @media (${bpWidth.tablet}) {
    margin-top: 100px;
  }
`;

export const CarouselHSSlidesCol = styled(Col)`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: hidden;
  overflow-x: scroll;

  h3 {
    ${eyebrowMStyles};
    padding: 30px 0 10px;
  }

  p {
    ${bodyMRegularUtopiaStyles};
  }

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 30px;

    h3 {
      ${eyebrowSStyles};
    }
    p {
      ${bodySRegularUtopiaStyles};
    }
  }

  @media (${bpWidth.mobile}) {
    h3 {
      padding-top: 20px;
    }

    p {
      width: 75%;
    }
  }
`;

export const CarouselHSSlides = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;

  .slideImage {
    height: 700px !important;
  }

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 30px;

    .slideImage {
      height: 500px !important;
    }
  }
  @media (${bpWidth.tablet}) {
    .slideImage {
      height: 600px !important;
    }
  }
  @media (${bpWidth.mobile}) {
    .slideImage {
      height: 400px !important;
    }
  }
`;

export const CarouselHSSlide = styled.div`
  margin-right: 24px;
  width: 75vw;

  &:first-child {
    margin-left: 8.333333333333334vw;
  }

  &:last-child {
    margin-right: 8.333333333333334vw;
  }

  @media (${bpWidth.desktopSm}) {
    margin-right: 20px;

    &:first-child {
      margin-left: 10vw;
    }

    &:last-child {
      margin-right: 10vw;
    }
  }

  @media (${bpWidth.tablet}) {
    margin-right: 16px;

    &:first-child {
      margin-left: 12.5vw;
    }

    &:last-child {
      margin-right: 12.5vw;
    }
  }

  @media (${bpWidth.mobile}) {
    margin-right: 10px;
  }
`;

export const CarouselHSCTA = styled(Link)`
  text-decoration: none;

  div {
    ${btnLgSecondaryMediumBlueStyles};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (${bpWidth.desktopSm}) {
    div {
      ${btnSmSecondaryMediumBlueStyles};
    }
  }
`;
