import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useIsomorphicLayoutEffect, useSsr, useStoryblokStories } from '../../../hooks';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { bodyLinkStyles, bodySRegularBrownStyles, bodyXsRegularBrownStyles } from '../../../styles/Web3.0/typography';
import DetailsPopUp from '../promotions/DetailsPopUp';
import Banner from '../promotions/Banner';
import FormikWrapper from '../../Web3.0/Header/FormikWrapper';
import CartIcon from '../../Web3.0/Cart/CartIcon';
import Hamburger from '../../Web3.0/Header/Hamburger';
import SearchSvg from '../../../icons/Web3.0/PFF-brand-icons-thin-search.svg';
import Menu from './Menu';
import { useAppState } from '../../../context/app-state.context';
import { useViewport } from '../../../context/viewport.context';

const HeaderContainerRow = styled(Row)`
  background-color: transparent;
  display: flex;
  top: 0;
  min-height: 60px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 22;
  transition: transform 0.5s;

  transform: ${({ translateY }) => {
    let yValue;
    if (translateY < 0) {
      yValue = 0;
    } else if (translateY > 1) {
      yValue = 1;
    } else {
      yValue = translateY;
    }

    yValue = (yValue || 0) * -100;
    return `translate3d(0, ${yValue}%, 0)`;
  }};

  @media (${bpWidth.mobile}) {
    min-height: 50px;
  }

  .navFontColorLight {
    a {
      color: ${colors.beige100};

      &.pffLogo {
        svg {
          fill: ${colors.beige100};
        }
        &.menuOpen svg {
          fill: ${colors.navy600};
        }
      }
    }

    button.hamburger {
      &::before,
      &::after {
        color: ${colors.beige100};
      }

      &.menuOpen {
        &::before,
        &::after {
          color: ${colors.navy600};
        }
      }
    }

    // some SVGs re-use a class and also have some inline styling for that class. this overrides.
    .st2 {
      fill: ${colors.beige100};
    }
  }

  &.bg-color {
    background-color: ${colors.beige100};

    .navFontColorLight {
      a {
        color: ${colors.navy600};

        &.pffLogo svg {
          fill: ${colors.navy600};
        }
      }

      button.hamburger {
        &::before,
        &::after {
          color: ${colors.navy600};
        }
      }
    }
  }

  // some SVGs re-use a class and also have some inline styling for that class. this overrides.
  .st2 {
    fill: ${colors.navy600};
  }
`;

const InnerHeaderRow = styled(Row)`
  display: flex;
  align-items: center;
  margin: 15px 0;

  a {
    ${bodyLinkStyles};
    ${bodySRegularBrownStyles};

    &.pffLogo {
      svg {
        height: auto;
        width: 125px;
      }
    }
  }
  @media (${bpWidth.desktopSm}) {
    a {
      ${bodyXsRegularBrownStyles};
    }
    svg.pffLogo {
      width: 110px;
    }
  }
  @media (${bpWidth.mobile}) {
    svg.pffLogo {
      width: 80px;
    }
  }
`;

const LogoCol = styled(Col)`
  z-index: 4;

  img {
    height: auto;
    width: 125px;

    @media (${bpWidth.desktopSm}) {
      width: 110px;
    }

    @media (${bpWidth.mobile}) {
      width: 80px;
    }
  }
`;

const NavItemCol = styled(Col)`
  display: flex;
  justify-content: space-between;

  > form {
    flex-basis: 330px;
  }

  a {
    text-transform: none;

    &:hover {
      color: inherit;
    }
  }

  @media (${bpWidth.desktopSm}) {
    > form {
      flex-basis: 200px;
    }
  }

  @media (${bpWidth.tablet}) {
    display: none;
  }
`;

const CartCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;

  @media (${bpWidth.tablet}) {
    button {
      padding: 0;
      margin-left: 44px;
    }
  }

  @media (${bpWidth.mobile}) {
    button {
      margin-left: 20%;
    }
  }
`;

const SearchIconContainer = styled.div`
  * {
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
    fill: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
  }
`;

const SearchIcon = styled(SearchSvg)`
  cursor: pointer;
  width: 25px;

  @media (${bpWidth.tablet}) {
    width: 20px;
    margin-right: 38px;
  }

  @media (${bpWidth.mobile}) {
    width: 17px;
    margin-right: 19px;
  }
`;

const MenuWrapperCol = styled(Col)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  display: none;

  @media (${bpWidth.tablet}) {
    display: block;
  }
`;

const Header = ({ blok }) => {
  const { links: primaryLinks = [], popularSearches = [], logoDark, logoLight } = blok;
  const { isBrowser } = useSsr();
  const {
    settings,
    setPrimaryNavLinks,
    setPopularSearches,
    searchIsOpen,
    toggleSearch,
    hamburgerMenuIsOpen,
    toggleHamburgerMenu,
    setHeaderHeight,
    headerTranslateY,
  } = useAppState();
  const { navFontColorLight = false, showCartIcon = false, showSearchIcon } = settings;
  const [logoSource, setLogoSource] = useState(logoDark);
  const headerContainerRowRef = useRef(null);
  const { viewWidth } = useViewport();

  useIsomorphicLayoutEffect(() => {
    if (navFontColorLight) {
      setLogoSource(logoLight);
    } else {
      setLogoSource(logoDark);
    }
  }, [navFontColorLight]);

  useIsomorphicLayoutEffect(() => {
    setPrimaryNavLinks(primaryLinks);
  }, [primaryLinks]);

  useIsomorphicLayoutEffect(() => {
    setPopularSearches(popularSearches);
  }, [popularSearches]);

  const logoClicked = () => {};

  // Showing promotion banner
  const banners = useStoryblokStories({
    content_type: 'promotionBanner',
    sort_by: 'content.startDate:desc,content.endDate:desc',
    resolve_relations: ['promotionBanner.exemptPages'],
    per_page: 1,
  });
  const stories = banners?.data?.stories || [];
  const [banner] = stories;

  const [showBanner, setShowBanner] = useState(false);

  // Showing 'See details' popup from banner click
  const [popUpOpen, setPopUpOpen] = useState(false);

  const isExemptPages = (story) => {
    return (
      window.location.pathname.startsWith(`/${story.path || story.full_slug}`) ||
      `${window.location.pathname}/`.startsWith(`/${story.path || story.full_slug}`)
    );
  };

  useIsomorphicLayoutEffect(() => {
    const currentDate = new Date();

    const bannerIsActive = !!(
      banner?.content?.permanent ||
      (new Date(banner?.content?.startDate) <= currentDate && new Date(banner?.content?.endDate) >= currentDate)
    );

    const exemptPages = isBrowser && banner?.content?.exemptPages?.some(isExemptPages);

    setShowBanner(!!(isBrowser && banner && bannerIsActive && !exemptPages));
  }, [banner, isBrowser]);

  const bannerOpenPopup = (onBannerClick) => {
    setPopUpOpen(onBannerClick);
    return popUpOpen;
  };
  const handleClosePopup = () => {
    setPopUpOpen(!popUpOpen);
  };

  useEffect(() => {
    if (headerContainerRowRef.current) {
      setTimeout(() => setHeaderHeight(headerContainerRowRef.current.clientHeight));
    }
  }, [viewWidth, headerContainerRowRef.current]);

  return (
    <div {...storyblokEditable(blok)}>
      {popUpOpen && <DetailsPopUp handleClosePopup={handleClosePopup} />}

      <HeaderContainerRow
        id="headerContainerRow"
        ref={headerContainerRowRef}
        className={!navFontColorLight ? 'bg-color' : undefined}
        translateY={headerTranslateY}
      >
        <Col xs={8} md={10} lg={12}>
          {showBanner && <Banner bannerClicked={popUpOpen} data={banner} onBannerClick={bannerOpenPopup} />}
        </Col>
        <Col xsOffset={1} mdOffset={0} xs={6} sm={6} md={10} lg={12}>
          <InnerHeaderRow className={navFontColorLight ? 'navFontColorLight' : undefined}>
            {/* Logo */}
            <LogoCol mdOffset={1} xs={2} sm={2} md={1} lg={1}>
              {logoSource?.filename && (
                <Link
                  to="/"
                  className={hamburgerMenuIsOpen ? 'pffLogo menuOpen' : 'pffLogo'}
                  onClick={hamburgerMenuIsOpen ? toggleHamburgerMenu : logoClicked}
                >
                  <img src={logoSource.filename} alt="logo" />
                </Link>
              )}
            </LogoCol>

            <Col xs={false} sm={false} md={1} lg={1} />

            <NavItemCol xs={false} sm={false} md={5} lg={6}>
              {primaryLinks.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} navFontColorLight={navFontColorLight} />
              ))}

              <FormikWrapper navFontColorLight={navFontColorLight} />
            </NavItemCol>

            <CartCol xsOffset={4} smOffset={4} mdOffset={0} lgOffset={1} xs={2} sm={2} md={1} lg={1}>
              {showSearchIcon && (
                <SearchIconContainer darkMode={navFontColorLight}>
                  <SearchIcon onClick={toggleSearch} />
                </SearchIconContainer>
              )}
              {showCartIcon && <CartIcon darkMode={navFontColorLight} />}
              {!searchIsOpen && (
                <Hamburger
                  className={hamburgerMenuIsOpen ? 'hamburger menuOpen' : 'hamburger'}
                  isX={hamburgerMenuIsOpen}
                  onClick={toggleHamburgerMenu}
                />
              )}
            </CartCol>
          </InnerHeaderRow>
        </Col>
      </HeaderContainerRow>
      {hamburgerMenuIsOpen && (
        <Row>
          <MenuWrapperCol xs={8} md={false}>
            <Menu />
          </MenuWrapperCol>
        </Row>
      )}
    </div>
  );
};

Header.propTypes = {
  blok: PropTypes.object.isRequired,
};

Header.defaultProps = {};

export default Header;
