/* eslint-disable no-cond-assign, no-restricted-globals */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { bodySRegularBrownStyles, buttonSStylesImportant } from '../../../styles/Web3.0/typography';
import {
  CHECK_EVERY_MILLISECONDS,
  MAXIMUM_TIMES_CHECKED,
  PRIVACY_MANAGER_ACTION,
  PRIVACY_MANAGER_API_NAME,
  SOURCE_DECISION,
  TRUST_ARC_COOKIE_NAME,
} from './constants';
import { getCookie } from '../../../utils/getCookie';

const ConsentBanner = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 21;
  display: none;

  #truste-consent-track {
    background-color: ${colors.beige200};
    margin: 0;
    padding: 0;
  }
  #truste-consent-content {
    border: 0;
    width: ${8 / 0.12}%;
    padding: 23px 0;
    margin: auto;

    @media (${bpWidth.desktopSm}) {
      width: ${8 / 0.1}%;
    }

    @media (${bpWidth.tablet}) {
      width: ${6 / 0.08}%;
      text-align: left;
    }

    .trustarc-banner-details {
      padding: 0;
      width: ${6 / 0.08}%;

      @media (${bpWidth.desktopSm}) {
        width: ${6 / 0.1}%;
      }

      @media (${bpWidth.tablet}) {
        width: ${6 / 0.08}%;
        flex-basis: 100%;
        text-align: left;
        margin-bottom: 20px;
      }
    }

    .trustarc-banner-actions {
      padding: 0;
      margin: 0;
      width: ${2 / 0.08}%;

      @media (${bpWidth.desktopSm}) {
        width: ${2 / 0.1}%;
      }

      @media (${bpWidth.tablet}) {
        width: ${6 / 0.08}%;
        flex-basis: 100%;
      }
    }
  }
  .trustarc-banner-footer,
  .trustarc-client-logo {
    display: none;
  }
  #truste-header-text {
    display: none;
  }
  #truste-consent-text {
    ${bodySRegularBrownStyles};

    a {
      font-weight: bold !important;
      color: inherit !important;
    }
  }
  #truste-consent-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: auto;

    button,
    button:visited,
    a,
    a:visited,
    button:hover,
    a:hover {
      ${buttonSStylesImportant};
      background: transparent !important;
      border-radius: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      height: auto !important;
      text-align: center;
      text-decoration: none;
    }

    #truste-show-consent,
    #truste-consent-button,
    #truste-consent-required {
      text-align: right;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;

      &:after {
        content: '\\276F';
        margin-left: 10px;
        font-size: 0.75em;
      }
    }

    #truste-show-consent {
      flex: 1 0 100%;
      order: 1;
      margin-bottom: 20px !important;
      color: ${colors.cerulean400} !important;

      &:hover {
        color: ${colors.cerulean700} !important;
      }
    }

    #truste-consent-button,
    #truste-consent-required {
      flex: 1 0 50%;
      color: ${colors.orange400} !important;

      &:hover {
        color: ${colors.orange700} !important;
      }
    }
    #truste-consent-button {
      order: 3;
    }

    #truste-consent-required {
      order: 2;
    }

    @media (${bpWidth.tablet}) {
      #truste-show-consent,
      #truste-consent-button,
      #truste-consent-required {
        flex: 1;
        margin: 0 !important;
      }

      #truste-show-consent {
        justify-content: flex-start;
      }
    }

    @media (${bpWidth.mobile}) {
      #truste-show-consent {
        flex: 1 0 100%;
        margin-bottom: 20px !important;
      }

      #truste-consent-required {
        display: inline-flex !important;
        justify-content: flex-start;
      }
    }
  }
`;

const TrustArcCookieBanner = () => {
  const [userCanBeTracked, setUserCanBeTracked] = useState(false);

  useEffect(() => {
    // Check if consent was previously given in cookie
    const trustArcCookie = getCookie(TRUST_ARC_COOKIE_NAME);

    if (trustArcCookie) {
      setUserCanBeTracked(true);
    }

    /**
     * Register with the TrustArc API to receive callbacks when a preference update occurs
     * You must wait for the TrustArc API (PrivacyManagerAPI object) to exist on the page before registering
     */
    let attempts = 0;
    const interval = setInterval(() => {
      if (window.PrivacyManagerAPI) {
        const apiObject = {
          PrivacyManagerAPI: {
            action: PRIVACY_MANAGER_ACTION.getConsent,
            timestamp: Date.now(),
            self: self.location.host,
          },
        };
        self.top.postMessage(JSON.stringify(apiObject), '*');
        clearInterval(interval);
      }

      attempts += 1;
      if (attempts > MAXIMUM_TIMES_CHECKED) clearInterval(interval);
    }, CHECK_EVERY_MILLISECONDS);

    /**
     * Callbacks will occur in the form of a PostMessage event.
     * This code listens for the appropriately formatted PostMessage event
     */
    const messageHandler = (event) => {
      try {
        let decision;

        if (
          event.data &&
          (decision = JSON.parse(event.data)) &&
          (decision = decision.PrivacyManagerAPI) &&
          decision.capabilities
        ) {
          if (decision.action === PRIVACY_MANAGER_ACTION.getConsent) {
            const { consentDecision: newDecision, source } = window.PrivacyManagerAPI.callApi(
              PRIVACY_MANAGER_API_NAME.getConsentDecision,
              decision.self
            );

            if (source === SOURCE_DECISION.implied) {
              self.truste?.bn?.openBanner();
            }

            if (!newDecision) return;

            if (newDecision > 0 && !userCanBeTracked) {
              setUserCanBeTracked(true);
            } else if (userCanBeTracked) {
              setUserCanBeTracked(false);
            }
          }
        }
      } catch (xx) {
        /** not a cm api message **/
      }
    };

    self.addEventListener('message', messageHandler);

    return () => {
      self.removeEventListener('message', messageHandler);
    };
  }, []);

  return <ConsentBanner id="consent-banner" />;
};

// Props
TrustArcCookieBanner.propTypes = {};

TrustArcCookieBanner.defaultProps = {};

export default TrustArcCookieBanner;
