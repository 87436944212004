/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { func } from 'prop-types';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import {
  eyebrowMStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
} from '../../../styles/Web3.0/typography.js';
import { bpWidth, colors } from '../../../styles/Web3.0/variables.js';
import CloseIcon from '../../../icons/Web3.0/close-icon.svg';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { useSsr, useStoryblokStories } from '../../../hooks';

const PopUpContainer = styled.div`
  color: ${colors.navy600};

  h3 {
    ${eyebrowMStyles};
    line-height: 1;
  }

  h4 {
    ${eyebrowSStyles};
    padding-bottom: 20px;
  }

  p {
    ${bodySRegularBrownStyles};
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  @media (${bpWidth.desktopSm}) {
    h3 {
      ${eyebrowSStyles};
      line-height: 1;
    }

    h4 {
      ${eyebrowXsStyles};
      padding-bottom: 10px;
    }

    p {
      ${bodyXsRegularBrownStyles};
    }
  }
  @media (${bpWidth.mobile}) {
    h3 {
      overflow-wrap: break-word;
    }
  }
`;

const PopUpBackgroundRow = styled(Row)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 23;
`;

const PopUpCol = styled(Col)`
  background: ${colors.beige100};
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 700px;
  padding: 50px;
  width: 38.9vw;

  @media (${bpWidth.desktopSm}) {
    max-height: 600px;
    padding: 40px;
    width: 46.7vw;
  }
  @media (${bpWidth.tablet}) {
    width: 58.3vw;
  }
  @media (max-height: 700px) {
    height: 75%;
  }
`;

const HeadingCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`;

const PopUpCopyRow = styled(Row)`
  overflow-x: hidden;
  overflow-y: scroll;

  -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  padding-bottom: 50px;

  #popUpCopy {
    padding-right: 60px;
  }

  @media (${bpWidth.desktopSm}) {
    #popUpCopy {
      padding-right: 50px;
    }
  }
  @media (${bpWidth.mobile}) {
    #popUpCopy {
      padding-right: 30px;
    }
  }
`;

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  svg {
    background: ${colors.beige100};
    width: 12px;
    z-index: 1;

    polygon.st0 {
      fill: ${colors.navy600};
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      width: 9px;
    }
  }
`;

function DetailsPopUp(props) {
  const { handleClosePopup } = props;

  const [shouldRender, setShouldRender] = useState(false);
  const { isBrowser } = useSsr();
  const popUpColRef = useRef(null);
  const closeButtonRef = useRef(null);

  // Modal entries from Storyblok, only 1st one is pulled
  const popUps = useStoryblokStories({
    content_type: 'detailPopup',
    sort_by: 'content.startDate:desc,content.endDate:desc',
    resolve_relations: ['detailPopup.exemptPages'],
    per_page: 1,
  });
  const stories = popUps?.data?.stories || [];
  const [popUp] = stories;

  // add scroll lock class when popup mounts
  useEffect(() => {
    document.body.classList.add('popUpFreezeScroll');
  }, [popUp]);

  const isExemptPages = (story) => {
    return (
      window.location.pathname.startsWith(`/${story.path || story.full_slug}`) ||
      `${window.location.pathname}/`.startsWith(`/${story.path || story.full_slug}`)
    );
  };

  // render popUp if there is a popUp story whose time range includes the current time
  useEffect(() => {
    const currentDate = new Date();

    // prettier-ignore
    if (
      popUp &&
      isBrowser &&
      !popUp?.content?.exemptPages?.some(isExemptPages) &&
      (
        popUp?.content?.permanent ||
        (new Date(popUp.content.startDate) <= currentDate && new Date(popUp.content.endDate) >= currentDate)
      )
    ) {
      setShouldRender(true);
    }
  }, [isBrowser, popUp]);

  const onClose = (event) => {
    if (
      (popUpColRef.current && !popUpColRef.current.contains(event.target)) ||
      (closeButtonRef.current && closeButtonRef.current.contains(event.target))
    ) {
      setShouldRender(false);
      handleClosePopup();
    }
  };

  // remove scroll lock class when popup unmounts
  useEffect(() => {
    return function cleanup() {
      document.body.classList.remove('popUpFreezeScroll');
    };
  }, []);

  return (
    <PopUpContainer>
      {shouldRender && (
        <PopUpBackgroundRow className="bg-[#00121999]" onClick={onClose}>
          <PopUpCol ref={popUpColRef}>
            <Row>
              <HeadingCol xs={8} md={10} lg={12}>
                <h3>{popUp.content.heading}</h3>

                <CloseButton ref={closeButtonRef}>
                  <CloseIcon />
                </CloseButton>
              </HeadingCol>
            </Row>
            <PopUpCopyRow id="popUpCopyContainer">
              <Col id="popUpCopy" xs={8} md={10} lg={12}>
                <div>{renderRichTextReact(popUp?.content?.body)}</div>
              </Col>
            </PopUpCopyRow>
          </PopUpCol>
        </PopUpBackgroundRow>
      )}
    </PopUpContainer>
  );
}

DetailsPopUp.propTypes = {
  handleClosePopup: func,
};

export default DetailsPopUp;
