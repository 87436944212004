import React, { useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import throttle from 'lodash/throttle';
import {
  bodyLRegularUtopiaStyles,
  bodyXlRegularUtopiaStyles,
  headerMStyles,
  headerSStyles,
  headerXsStyles,
} from '../../styles/Web3.0/typography';
import { bpWidth, bpWidthTailwind, colors } from '../../styles/Web3.0/variables';
import { inputLabels } from '../../state/settings/settingsReducer';
import { useIsomorphicLayoutEffect } from '../../hooks';
import { useAppState } from '../../context/app-state.context';
import {
  CarouselFPCol,
  CarouselFPTextBottomRow,
  CarouselFPTextRow,
  CarouselFPTextTopRow,
} from '../Carousels/CarouselStyles';
import { renderRichTextReact } from '../../utils/storyblokRichText';
import { useViewport } from '../../context/viewport.context';
import { breakpoint } from '../../constants/breakpoint';
import { SHOP_URL } from '../../constants';
import { tiktokClickButton } from '../../utils/tiktokEvents';
import { isBrowser } from '../../utils/helper';
import { btnLgPrimaryThinOrangeStyles, btnSmPrimaryThinOrangeStyles } from '../../styles/Web3.0/buttons';
import PauseSvgIcon from '../../icons/Web3.0/pause-button.svg';
import PlaySvgIcon from '../../icons/Web3.0/play-button.svg';

const SectionCol = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 10;

  @supports (height: 100dvh) {
    & {
      height: 100dvh;
    }
  }

  .hero-control-play {
    svg {
      g#pause-play-button-L {
        g#outline-2 {
          stroke: #f2f1ee;
        }

        g#pause {
          rect#Rectangle_963-2,
          rect#Rectangle_964-2 {
            fill: #f2f1ee;
          }
        }

        path#play-2 {
          fill: #f2f1ee;
        }
      }
    }

    &:hover {
      svg {
        g#pause-play-button-L {
          g#outline-2 {
            stroke: #f2f1ee;
          }

          g#pause {
            rect#Rectangle_963-2,
            rect#Rectangle_964-2 {
              fill: #f2f1ee;
            }
          }

          path#play-2 {
            fill: #f2f1ee;
          }
        }
      }
    }

    @media (${bpWidthTailwind.desktopSm}) {
      svg {
        g#pause-play-button-L {
          g#outline-2 {
            stroke: rgba(242, 241, 238, 0.5);
          }

          g#pause {
            rect#Rectangle_963-2,
            rect#Rectangle_964-2 {
              fill: rgba(242, 241, 238, 0.5);
            }
          }

          path#play-2 {
            fill: rgba(242, 241, 238, 0.5);
          }
        }
      }
    }
  }
`;

const MediaRow = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  ${headerMStyles};
  color: ${colors.beige100};

  @media (${bpWidth.desktopSm}) {
    ${headerSStyles};
    color: ${colors.beige100};
  }

  @media (${bpWidth.mobile}) {
    ${headerXsStyles};
    color: ${colors.beige100};
  }

  img {
    display: inline-block;
  }

  img:nth-of-type(1) {
    margin-bottom: -9px;
    height: auto;
    width: 110px;

    @media (${bpWidth.desktopSm}) {
      width: 98px;
      margin-bottom: -8px;
    }

    @media (${bpWidth.mobile}) {
      width: 72px;
      margin-bottom: -5px;
    }
  }

  img:nth-of-type(2) {
    fill: #ffffff;
    margin-bottom: -8px;
    height: auto;
    width: 110px;

    * {
      fill: #ffffff;
    }

    @media (${bpWidth.desktopSm}) {
      width: 98px;
      margin-bottom: -8px;
    }

    @media (${bpWidth.mobile}) {
      width: 72px;
      margin-bottom: -5px;
    }
  }
`;

const Subtitle = styled.div`
  ${bodyXlRegularUtopiaStyles};
  color: ${colors.beige100};
  padding-right: 20px;

  @media (${bpWidth.desktopSm}) {
    ${bodyLRegularUtopiaStyles};
    color: ${colors.beige100};
  }

  p {
    display: inline;
  }

  /* styling for colored Father's Day code */
  h6 {
    display: inline;
    color: ${colors.cerulean100};

    ::before {
      content: ' ';
    }
  }
`;

const ShopButton = styled.button`
  ${btnSmPrimaryThinOrangeStyles};
  cursor: pointer;
  width: auto;
  min-width: 149px;
  padding: 0 20px;
  pointer-events: auto;

  :hover {
    border-color: ${colors.orange700};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${btnLgPrimaryThinOrangeStyles};
    cursor: pointer;
    width: auto;
    min-width: 220px;
    padding: 0 20px;

    :hover {
      border-color: ${colors.orange700};
    }
  }
`;

const Hero = ({ blok }) => {
  const { title, subtitle } = blok;
  const loadType = 'lazy';
  const ttq = isBrowser ? window.ttq || {} : {};

  const { settings, dispatchSetting } = useAppState();
  const { viewWidth } = useViewport();
  const [imageBlok, setImageBlok] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [videoBlok, setVideoBlok] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [posterData, setPosterData] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const sectionRef = useRef(null);
  const videoRef = useRef(null);

  useIsomorphicLayoutEffect(() => {
    const checkWindowPageYOffset = () => {
      if (typeof window !== 'undefined') {
        if (window.scrollY > sectionRef.current.offsetHeight) {
          dispatchSetting({
            name: inputLabels.navFontColorLight,
            value: false,
          });
        } else {
          dispatchSetting({
            name: inputLabels.navFontColorLight,
            value: settings.navLightMode,
          });
        }
      }
    };

    checkWindowPageYOffset();
    const throttledCheckWindowPageYOffset = throttle(checkWindowPageYOffset, 100);

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', throttledCheckWindowPageYOffset);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', throttledCheckWindowPageYOffset);
      }
    };
  }, [settings.navLightMode]);

  useIsomorphicLayoutEffect(() => {
    if (blok.image?.length > 0) {
      const [asset] = blok.image;
      const isImage = asset.mediaType === 'image';
      const isVideo = asset.mediaType === 'video';
      setImageBlok(null);
      setImageData(null);
      setVideoBlok(null);
      setPosterData(null);
      setVideoData(null);
      if (videoRef.current) {
        videoRef.current.load();
      }

      if (isImage) {
        setImageBlok(asset);
      }

      if (isVideo) {
        setVideoBlok(asset);
      }

      if (isImage) {
        const imageUrl =
          asset?.assetLg?.filename || asset?.assetMd?.filename || asset?.assetSm?.filename || asset?.assetXs?.filename;

        if (imageUrl) {
          const options = {
            layout: 'constrained',
            breakpoints: [414, 768, 1280, 1920],
            width: 1920,
          };
          const artDirected = [];
          if (asset?.assetXs?.filename) {
            artDirected.push({
              media: `(${bpWidth.mobile})`,
              image: getGatsbyImage(asset?.assetXs?.filename, { ...options, width: 414 }),
            });
          }
          if (asset?.assetSm?.filename) {
            artDirected.push({
              media: `(${bpWidth.tablet})`,
              image: getGatsbyImage(asset?.assetSm?.filename, { ...options, width: 768 }),
            });
          }
          if (asset?.assetMd?.filename) {
            artDirected.push({
              media: `(${bpWidth.desktopSm})`,
              image: getGatsbyImage(asset?.assetMd?.filename, { ...options, width: 1280 }),
            });
          }

          const imageArtDirection = withArtDirection(getGatsbyImage(imageUrl, options), artDirected);
          setImageData(imageArtDirection);
        }
      }

      if (isVideo) {
        let posterUrl =
          asset?.posterXs?.filename ||
          asset?.posterSm?.filename ||
          asset?.posterMd?.filename ||
          asset?.posterLg?.filename;

        if (posterUrl) {
          if (viewWidth >= breakpoint.sm) {
            if (asset?.posterSm?.filename) {
              posterUrl = asset?.posterSm?.filename;
            }
          }
          if (viewWidth >= breakpoint.md) {
            if (asset?.posterMd?.filename) {
              posterUrl = asset?.posterMd?.filename;
            }
          }
          if (viewWidth >= breakpoint.lg) {
            if (asset?.posterLg?.filename) {
              posterUrl = asset?.posterLg?.filename;
            }
          }

          setPosterData(posterUrl);
        }

        let videoUrl =
          asset?.assetXs?.filename || asset?.assetSm?.filename || asset?.assetMd?.filename || asset?.assetLg?.filename;

        if (videoUrl) {
          if (viewWidth >= breakpoint.sm) {
            if (asset?.assetSm?.filename) {
              videoUrl = asset?.assetSm?.filename;
            }
          }
          if (viewWidth >= breakpoint.md) {
            if (asset?.assetMd?.filename) {
              videoUrl = asset?.assetMd?.filename;
            }
          }
          if (viewWidth >= breakpoint.lg) {
            if (asset?.assetLg?.filename) {
              videoUrl = asset?.assetLg?.filename;
            }
          }

          setVideoData(videoUrl);
          if (videoRef.current) {
            videoRef.current.load();
          }
        }
      }
    }
  }, [blok.image, videoRef.current, viewWidth]);

  const updateIsPlayingState = (value) => {
    setIsPlay(value);
  };

  const togglePlay = useCallback(() => {
    if (videoRef.current) {
      if (isPlay) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }, [isPlay, videoRef.current]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('play', updateIsPlayingState.bind(this, true));
      videoRef.current.addEventListener('pause', updateIsPlayingState.bind(this, false));
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('play', updateIsPlayingState.bind(this, true));
        videoRef.current.removeEventListener('pause', updateIsPlayingState.bind(this, false));
      }
    };
  }, [videoRef.current]);

  const shopButtonClick = (e) => {
    e.preventDefault();

    if (_.has(ttq, 'track')) {
      // ensure ttq has been loaded
      tiktokClickButton(ttq);
    }

    navigate('/shop');
  };

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <SectionCol ref={sectionRef}>
        <MediaRow>
          {imageData && (
            <GatsbyImage
              image={imageData}
              alt={imageBlok?.alt || imageBlok?.title || 'hero-image'}
              loading={loadType}
              style={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: '0',
                left: '0',
                objectFit: 'cover',
                objectPosition: 'bottom center',
              }}
            />
          )}
          {videoBlok && (
            <video
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              poster={posterData}
              className="w-full h-full object-cover"
            >
              <source src={`${videoData}`} type="video/webm" />
            </video>
          )}
        </MediaRow>
        <CarouselFPTextRow>
          <Row>
            <Col xsOffset={1} mdOffset={0} xs={6} sm={6} md={10} lg={12}>
              <CarouselFPTextTopRow>
                <CarouselFPCol xs={8} sm={6} mdOffset={1} md={5} lgOffset={2} lg={5}>
                  <Title>{renderRichTextReact(title)}</Title>
                </CarouselFPCol>
              </CarouselFPTextTopRow>
              <CarouselFPTextBottomRow className="gap-y-7 sm:gap-y-5">
                <CarouselFPCol xs={8} sm={6} mdOffset={1} md={5} lgOffset={2} lg={5}>
                  <div className="h-full flex items-end">
                    <Subtitle>{renderRichTextReact(subtitle)}</Subtitle>
                  </div>
                </CarouselFPCol>
                <Col xs={8} md={3} lg={4}>
                  <div className="w-full flex md:justify-end">
                    <a href={SHOP_URL} target="_blank" rel="noreferrer" onClick={shopButtonClick}>
                      <ShopButton>Learn more</ShopButton>
                    </a>
                  </div>
                </Col>
              </CarouselFPTextBottomRow>
            </Col>
          </Row>
        </CarouselFPTextRow>
        {videoRef.current && (
          <button
            type="button"
            className="absolute right-6 bottom-6 cursor-pointer hero-control-play"
            onClick={togglePlay}
          >
            {isPlay ? <PauseSvgIcon /> : <PlaySvgIcon />}
          </button>
        )}
      </SectionCol>
    </Col>
  );
};

Hero.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Hero;
